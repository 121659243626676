const mercuryConstants = {
    majorAxis: 10,
    minorAxis: 8.78,
    tilt: 0.03488,
    rotationSpeed: 0.001,
    orbitalPeriod: 90,
    modelScale: [0.03, 0.03, 0.03],
    color: "rgba(168, 60, 50, 0.05)",
    hoverColor: "rgba(255, 22, 0, 1)",
    selectedPlanet: 1,
    radius: 1,
    offsetX: 1.105,
    axialTilt: 0.192173,
    offsetY: 0,
    offsetZ: 0,
    textNormalColor: "white",
    textHoverColor: "yellow",
};

const venusConstants = {
    majorAxis: 18.94,
    minorAxis: 18.94,
    tilt: 0.01987,
    rotationSpeed: 0.005,
    orbitalPeriod: 200,
    modelScale: [0.06, 0.06, 0.06],
    color: "rgba(156, 82, 45,1)",
    hoverColor: "rgba(255, 85, 0, 1)",
    selectedPlanet: 2,
    radius: 2,
    axialTilt: 0.122173,
    offsetX: 0,
    offsetY: 0,
    offsetZ: 0,
    textNormalColor: "white",
    textHoverColor: "yellow",
};

const earthConstants = {
    majorAxis: 26.13,
    minorAxis: 26.13,
    tilt: 0,
    rotationSpeed: 0.001,
    orbitalPeriod: 365,
    modelScale: [0.11, 0.11, 0.11],
    color: "rgba(40, 42, 117, 1)",
    hoverColor: "rgba(0, 7, 255,1)",
    selectedPlanet: 3,
    radius: 3,
    axialTilt: 0.0409105,
    offsetX: 0,
    offsetY: 0,
    offsetZ: 0,
    textNormalColor: "white",
    textHoverColor: "yellow",
    moon: {
        majorAxis: 2.5,
        minorAxis: 2.5,
        tilt: 0.03488,
        rotationSpeed: 0.001,
        orbitalPeriod: 30,
        modelScale: [0.04, 0.04, 0.04],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 2,
    }
};

const marsConstants = {
    majorAxis: 39.82,
    minorAxis: 35.64,
    tilt: 0.03228859,
    rotationSpeed: 0.001867,
    orbitalPeriod: 705,
    modelScale: [0.08, 0.08, 0.08],
    color: "rgba(105, 104, 30, 1)",
    hoverColor: "rgba(255, 252, 0,1)",
    selectedPlanet: 4,
    radius: 2,
    axialTilt: 0.122173,
    offsetX: 0,
    offsetY: 0,
    offsetZ: 0,
    textNormalColor: "white",
    textHoverColor: "yellow",
    phobos: {
        majorAxis: 1.25,
        minorAxis: 0.95,
        tilt: 0.03488,
        rotationSpeed: 0.01,
        orbitalPeriod: 0.3,
        modelScale: [1, 1, 1],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 0.5,
    },
    deimos: {
        majorAxis: 2.5,
        minorAxis: 1.75,
        tilt: 0.03488,
        rotationSpeed: 0.001,
        orbitalPeriod: 1.3,
        modelScale: [2, 2, 2],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 1.5,
    }
};

const jupiterConstants = {
    majorAxis: 69.46,
    minorAxis: 64.96,
    tilt: 0.02286,
    rotationSpeed: 0.001,
    orbitalPeriod: 4380,
    modelScale: [0.15, 0.15, 0.15],
    color: "rgba(79, 122, 40,1)",
    hoverColor: "rgba(105, 209, 10,1)",
    selectedPlanet: 5,
    radius: 2,
    axialTilt: 0.122173,
    offsetX: 0,
    offsetY: 0,
    offsetZ: 0,
    textNormalColor: "white",
    textHoverColor: "yellow",
    io: {
        majorAxis: 1.25,
        minorAxis: 0.95,
        tilt: 0.03488,
        rotationSpeed: 0.01,
        orbitalPeriod: 28,
        modelScale: [0.02, 0.02, 0.02],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 1.5,
    },
    europa: {
        majorAxis: 2.5,
        minorAxis: 1.75,
        tilt: -0.03488,
        rotationSpeed: 0.001,
        orbitalPeriod: 45,
        modelScale: [0.01, 0.01, 0.01],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 2.5,
    },
    ganymede: {
        majorAxis: 3.5,
        minorAxis: 2.75,
        tilt: 0.03488,
        rotationSpeed: 0.001,
        orbitalPeriod: 75,
        modelScale: [0.03, 0.03, 0.03],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 3.5,
    },
    callisto: {
        majorAxis: 4.5,
        minorAxis: 3.75,
        tilt: 0.03488,
        rotationSpeed: 0.001,
        orbitalPeriod: 16.7,
        modelScale: [0.04, 0.04, 0.04],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 4.5,
    }
};

const saturnConstants = {
    majorAxis: 113.72,
    minorAxis: 108.72,
    tilt: 0.043633,
    rotationSpeed: 0.001,
    orbitalPeriod: 10767,
    modelScale: [0.16, 0.16, 0.16],
    color: "rgba(37, 125, 96,1)",
    hoverColor: "rgba(7, 224, 153,1)",
    selectedPlanet: 6,
    radius: 2,
    axialTilt: 0.122173,
    offsetX: 0,
    offsetY: 0,
    offsetZ: 0,
    textNormalColor: "white",
    textHoverColor: "yellow",
    titan: {
        majorAxis: 7.25,
        minorAxis: 6.45,
        tilt: 0.33488,
        rotationSpeed: 0.01,
        orbitalPeriod: 15,
        modelScale: [0.02, 0.02, 0.02],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 1.5,
    },
    rhea : {
        majorAxis: 3.1,
        minorAxis: 2.75,
        tilt: -0.03488,
        rotationSpeed: 0.001,
        orbitalPeriod: 5,
        modelScale: [0.01, 0.01, 0.01],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 2.5,
    },
    iapetus: {
        majorAxis: 9.5,
        minorAxis: 7.75,
        tilt: 0.03488,
        rotationSpeed: 0.001,
        orbitalPeriod: 5,
        modelScale: [0.03, 0.03, 0.03],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 3.5,
    },
};

const uranusConstants = {
    majorAxis: 227.92,
    minorAxis: 222.92,
    tilt: 0.013,
    rotationSpeed: 0.001,
    orbitalPeriod: 30660,
    modelScale: [0.24, 0.24, 0.24],
    color: "rgba(72, 21, 102,1)",
    hoverColor: "rgba(146, 13, 224,1)",
    selectedPlanet: 7,
    radius: 3,
    axialTilt: 0.122173,
    offsetX: 0,
    offsetY: 0,
    offsetZ: 0,
    textNormalColor: "white",
    textHoverColor: "yellow",
    titania: {
        majorAxis: 19.5,
        minorAxis: 15.75,
        tilt: 0.03488,
        rotationSpeed: 0.001,
        orbitalPeriod: 20,
        modelScale: [0.03, 0.03, 0.03],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 3.5,
    },
    ariel: {
        majorAxis: 9.25,
        minorAxis: 10.95,
        tilt: 0.03488,
        rotationSpeed: 0.01,
        orbitalPeriod: 28,
        modelScale: [0.02, 0.02, 0.02],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 1.5,
    },
    umbriel: {
        majorAxis: 5.5,
        minorAxis: 7.75,
        tilt: -0.03488,
        rotationSpeed: 0.001,
        orbitalPeriod: 45,
        modelScale: [0.05, 0.05, 0.05],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 2.5,
    },
    oberon: {
        majorAxis: 2.5,
        minorAxis: 1.75,
        tilt: 0.03488,
        rotationSpeed: 0.001,
        orbitalPeriod: 19.7,
        modelScale: [0.04, 0.04, 0.04],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 4.5,
    }
};

const neptuneConstants = {
    majorAxis: 368.7,
    minorAxis: 363.7,
    tilt: 0.03089,
    rotationSpeed: 0.001,
    orbitalPeriod: 60152,
    modelScale: [0.53, 0.53, 0.53],
    color: "rgba(117, 16, 35,1)",
    hoverColor: "rgba(235, 12, 54,1)",
    selectedPlanet: 8,
    radius: 8,
    axialTilt: 0.122173,
    offsetX: 0,
    offsetY: 0,
    offsetZ: 0,
    textNormalColor: "white",
    textHoverColor: "yellow",
    triton: {
        majorAxis: 8.5,
        minorAxis: 7.75,
        tilt: 0.03488,
        rotationSpeed: 0.001,
        orbitalPeriod: 20,
        modelScale: [0.1, 0.1, 0.1],
        color: "rgba(168, 60, 50, 0.05)",
        hoverColor: "rgba(255, 22, 0, 1)",
        distance: 3.5,
    },
};

const starsConstants = {
    distance: 500,
    lowerLimit: 0,
    upperLimit: 3.14 * 2,
    color: 0x888888,
    rotationSpeed: 0.0001,
    starCount: 4000,
};

module.exports = {
    mercuryConstants,
    venusConstants,
    earthConstants,
    marsConstants,
    jupiterConstants,
    saturnConstants,
    uranusConstants,
    neptuneConstants,
    starsConstants,
};